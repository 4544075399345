<template>

    <div class="component-container export-container">

        <h3>Export your data</h3>
        <p>{{ user_selection.selected_documents_count }} document<span v-if="user_selection.selected_documents_count>1">s</span> selected</p>

        <PanelHideShow class="query-container" size="small" :expanded_init="true" v-for="(collections, query) in user_selection.selected_documents" :key="query">

            <template v-slot:title>{{ query }}</template>

            <template v-slot:content>

                <div v-for="(types, collection) in collections" :key="collection">
                    <p>{{ collection }}</p>
                    <div v-for="(documents, type) in types" :key="collection+'_'+type">
                        <table v-if="type=='search'">
                            <tr><th>Identifier</th><th>Title</th><th>Date</th><th>Score</th></tr>
                            <tr v-for="(details, document) in documents" :key="document">
                                <td width="10%">{{ details._id }}</td>
                                <td width="70%" v-if="collection == 'PLAZI'">{{ details[doc_source].treatment_title }}</td>
                                <td width="70%" v-else>{{ details[doc_source].title }}</td>
                                <td width="15%">{{ details[doc_source].pubyear}}</td>
                                <td width="5%" v-if="'_score' in details">{{ details._score.toFixed(2)}}</td>
                                <td width="5%" v-if="'score' in details">{{ details.score.toFixed(2)}}</td>
                            </tr>
                        </table>
                        <table v-else>
                            <tr><th>Answer</th><th>Docid</th><th>Score</th></tr>
                            <tr v-for="(details, document) in documents" :key="document">
                                <td width="60%">{{ details.answer }}</td>
                                <td width="30%">{{ details.docid}}</td>
                                <td width="10%">{{ details.answer_score.toFixed(2)}}</td>
                            </tr>
                        </table>
                    </div>
                </div>

            </template>

        </PanelHideShow>

        <div v-if="user_selection.selected_documents_count > 0">
            <button @click="saveBookmarkJson">Export selected publications in JSON</button>
            <button @click="saveBookmarkCsv">Export selected publications in CSV (Excel)</button><br/><br/>
        </div>
        <div>
            <button @click="saveAllCsv">Export all in CSV (Excel)</button>
            <button @click="saveAllJson">Export all in JSON</button>
        </div>

    </div>

</template>

<script>

    import { mapState } from 'vuex'
    import PanelHideShow from '@/components/PanelHideShow.vue'

    export default {
      name: 'ExportBox',
      components: {
        PanelHideShow,
      },
      computed: {
        ...mapState(['user_selection', 'user_query', 'theme_color', "documents", 'search_results', 'qa_results', 'doc_source']),
            cssVars () {
                return{
                    '--color-main': this.theme_color.main,
                }
            }
      },
      methods: {
        getFileName(){
            var d = new Date();
            var date = d.getFullYear()+'-'+("00" + (d.getMonth()+1)).slice(-2)+'-'+("00" + d.getDate()).slice(-2)+"-"+("00" + d.getHours()).slice(-2)+"h"+("00" + d.getMinutes()).slice(-2)
            return "sibils_"+date;
        },
        saveBookmarkJson() {

            var final_json = {}

            for (var query in this.user_selection.selected_documents){
                final_json[query] = {}
                for (var collection in this.user_selection.selected_documents[query]){
                    final_json[query][collection] = []
                    for (var doc in this.user_selection.selected_documents[query][collection]){
                        final_json[query][collection].push(this.user_selection.selected_documents[query][collection][doc])
                    }
                }
            }

            var string_json = JSON.stringify(final_json)
            var textToSaveAsBlob = new Blob([string_json], {type:"text/json"});
            var textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);
            var fileNameToSaveAs = this.getFileName()+".json";

            var downloadLink = document.createElement("a");
            downloadLink.download = fileNameToSaveAs;
            downloadLink.innerHTML = "Download File";
            downloadLink.href = textToSaveAsURL;
            downloadLink.onclick = this.destroyClickedElement;
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            this.$matomo?.trackEvent("Save", "selection json");
            downloadLink.click();

        },
        saveAllJson() {

            var final_json = {}
            if (this.user_query.mode_exec == "qa"){
                final_json["medline"] = this.qa_results.medline
                final_json["pmc"] = this.qa_results.pmc
                final_json["plazi"] = this.qa_results.plazi
                final_json["suppdata"] = this.qa_results.suppdata
            }
            else {
                final_json["medline"] = this.search_results.medline
                final_json["pmc"] = this.search_results.pmc
                final_json["plazi"] = this.search_results.plazi
                final_json["suppdata"] = this.search_results.suppdata
            }

            var string_json = JSON.stringify(final_json)
            var textToSaveAsBlob = new Blob([string_json], {type:"text/json"});
            var textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);
            var fileNameToSaveAs = this.getFileName()+".json";

            var downloadLink = document.createElement("a");
            downloadLink.download = fileNameToSaveAs;
            downloadLink.innerHTML = "Download File";
            downloadLink.href = textToSaveAsURL;
            downloadLink.onclick = this.destroyClickedElement;
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            this.$matomo?.trackEvent("Save", "all json");
            downloadLink.click();

            },
        saveBookmarkCsv() {

            var final_csv = "query;collection;doc_id;title;authors;date;answer;score\n"

            for (var query in this.user_selection.selected_documents){
                for (var collection in this.user_selection.selected_documents[query]){
                    for (var type in this.user_selection.selected_documents[query][collection]){
                        for (var doc in this.user_selection.selected_documents[query][collection][type]){
                            if (type == "search"){
                                let line = query+";";
                                line += collection+";";
                                line += this.user_selection.selected_documents[query][collection][type][doc]['_id']+";"
                                if (collection == "PLAZI"){
                                    line += this.user_selection.selected_documents[query][collection][type][doc][this.doc_source]['treatment_title']+";"
                                }
                                else {
                                    line += this.user_selection.selected_documents[query][collection][type][doc][this.doc_source]['title']+";"
                                }
                                line += this.user_selection.selected_documents[query][collection][type][doc][this.doc_source]['authors']+";"
                                line += this.user_selection.selected_documents[query][collection][type][doc][this.doc_source]['pubyear']+";"
                                line += ";"
                                line += this.user_selection.selected_documents[query][collection][type][doc]['_score']
                                final_csv += line +"\n"
                            }
                            else {
                                let line = query+";";
                                line += collection+";";
                                line += this.user_selection.selected_documents[query][collection][type][doc]['docid']+";"
                                line += ";"
                                line += ";"
                                line += ";"
                                line += this.user_selection.selected_documents[query][collection][type][doc]['answer']+";"
                                line += this.user_selection.selected_documents[query][collection][type][doc]['answer_score']
                                final_csv += line +"\n"
                            }
                        }
                    }
                }
            }

            var textToSaveAsBlob = new Blob([final_csv], {type:"text/plain"});
            var textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);
            var fileNameToSaveAs = this.getFileName()+".csv";

            var downloadLink = document.createElement("a");
            downloadLink.download = fileNameToSaveAs;
            downloadLink.innerHTML = "Download File";
            downloadLink.href = textToSaveAsURL;
            downloadLink.onclick = this.destroyClickedElement;
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            this.$matomo?.trackEvent("Save", "selection csv");

            downloadLink.click();

        },
        saveAllCsv() {

            var final_csv = "query;collection;doc_id;title;authors;date;answer;score\n"
            var query = this.user_query.search

            var collections = ["medline", "pmc", "plazi", "suppdata"]
            for (var c=0; c<collections.length; c++){
                var collection = collections[c]
                    var list = []
                    if (this.user_query.mode_exec == "search"){
                        list = this.search_results[collection]
                        for (let doc in list){
                            let line = query+";";
                            line += collection+";";
                            line += list[doc]['_id']+";"
                            if (collection == "PLAZI"){
                                line += list[doc][this.doc_source]['treatment_title']+";"
                            }
                            else {
                                line += list[doc][this.doc_source]['title']+";"
                            }
                            line += list[doc][this.doc_source]['authors']+";"
                            line += list[doc][this.doc_source]['pubyear']+";"
                            line += ";"
                            line += list[doc]['_score']
                            final_csv += line +"\n"
                        }
                    }
                    else {
                        list = this.qa_results[collection]
                        for (let doc in list){
                            let line = query+";";
                            line += collection+";";
                            line += list[doc]['docid']+";"
                            line += ";"
                            line += ";"
                            line += ";"
                            line += list[doc]['answer']+";"
                            line += list[doc]['answer_score']
                            final_csv += line +"\n"
                        }
                    }
                    
            }


            var textToSaveAsBlob = new Blob([final_csv], {type:"text/plain"});
            var textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);
            var fileNameToSaveAs = this.getFileName()+".csv";

            var downloadLink = document.createElement("a");
            downloadLink.download = fileNameToSaveAs;
            downloadLink.innerHTML = "Download File";
            downloadLink.href = textToSaveAsURL;
            downloadLink.onclick = this.destroyClickedElement;
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            this.$matomo?.trackEvent("Save", "all csv");
            downloadLink.click();

            },
        destroyClickedElement(event){
            document.body.removeChild(event.target);
        }
      }
    }

</script>

<style scoped lang="scss">

    .export-container {
        padding: 10px 20px 20px 20px;
        background-color: #F2F2F2;
        border-radius: 10px;
        border: 1px #aaa solid;
    }
    .query-container {
        text-align: left;
        margin-bottom: 40px;
    }
    .export-container button {
        font-weight: bold;
        color: #FFFFFF;
        text-decoration: none;
        background-color: var(--color-main);
        border-radius: 5px;
        padding: 5px 10px;
        border: solid 1px var(--color-main);
        margin: 0 5px;
    }
    .export-container button:hover {
        text-decoration: none;
        cursor: pointer;
        color: #CCCCCC;
    }

    .export-container table {
      margin-bottom: 20px;
      border-collapse: collapse;
      width: 100%;
      font-size: 0.8em;
      background-color: #eee
    }

    .export-container td, .export-container th {
      border: 1px solid #ddd;
      padding: 6px;
      text-align: left;
    }

    .export-container tr:nth-child(even){
      background-color: #fff;
    }

    .export-container tr:hover {
      background-color: #ddd;
    }

    .export-container th {
      padding-top: 6px;
      padding-bottom: 6px;
      background-color: rgb(76, 73, 73);
      color: white;
    }



</style>
