<template>

    <div class="article-container row" v-if="document_init">

        <div class="col-12">

            <div class="row">

                <div class="col-1 left-column">
                    <span class="rank">{{ getRank }}</span><br/>
                </div>

                <div class="col-10 middle-column">

                    <h3 v-if="collection_name=='plazi'" v-html="highlight(getTreatmentTitle)"/>
                    <h3 v-else v-html="highlight(getTitle)"/>

                    <div class="biblio">
                        <p>
                            {{ getId }}.
                            {{ getAuthors }}
                            {{ getDate }}
                            {{ getJournal }}
                            {{ getPublicationTypes }}
                            {{ getLicence }}
                            <ExternalLinks :int_links="getInternalLinks" :ext_links="getExternalLinks"/>                            
                        </p>
                    </div>

                </div>

                <div class="col-1 right-column">

                    <div class="article-menu">

                        <div class="row">
                            <Bookmark  v-if="user_query.mode_exec!='doc'" :bookmark_init="isInList" :collection_name="collection_name" :collection_title="collection_title" :doc_id="document._id" :item="document" :position="getRank"/>
                        </div>

                        <div class="row">
                            <span v-if="user_query.mode_exec!='doc'" class="score">score<br/>{{ getScore }}</span>
                        </div>

                        <div class="row">
                            <span class="expand">
                                <img src="../assets/images/icon_expand_on.png" alt="[+]" v-show="!expanded_status" @click="expandDoc()"/>
                                <img src="../assets/images/icon_expand_off.png" alt="[-]" v-show="expanded_status"  @click="expandDoc()"/>
                            </span>
                    </div>

                    </div>

                </div>

            </div>

            <div v-if="expanded_status" class="row more">
 
                <div v-if="collection_name=='pmc' || collection_name=='medline' || collection_name == 'plazi'" >
                    <button class="link-button" @click="displayViewerFullPage">One Tab View</button>
                    <FulltextViewer :id="document._id" :collection="collection_name" :terms_to_highlight="highlighted_terms"/>
                </div>

                <div v-if="collection_name=='suppdata'">
                    
                    <div v-if="isImage">
                        <img :src="getLinkSupp"/>
                        <br/>
                    </div>

                    <a :href="getLinkSupp" target="_blank">Download file {{ getFileName }}</a>
                    <br/><br/>
                    
                    <div v-if="getText.length > 0">
                        <label v-if="isImage">OCR-ized text</label>
                        <label v-else>Text</label>
                        <p v-html="split(highlight(getText))"></p>
                    </div>    

                </div>

                <div class="issue-container">
                    <a :href="get_report_link" target="_blank"><span class="bug-link">Report a bug for document {{ getId }}?</span></a>            
                </div>

            </div>

        </div>



  </div>


<div id="test" class="col-12" v-else>

    <div v-if="in_progress" class="progress-container">
            <PulseLoader :color="theme_color.main"/>
    </div>

    <div v-if="!in_progress && !warning">

        <div>

            <h3 v-if="collection_name=='plazi'" v-html="highlight(getTreatmentTitle)"/>
            <h3 v-else v-html="highlight(getTitle)"/>

            <div class="biblio">
                <p>
                    {{ getId }}.
                    {{ getAuthors }}
                    {{ getDate }}
                    {{ getJournal }}
                    {{ getPublicationTypes }}
                    {{ getLicence }}
                    <ExternalLinks :int_links="getInternalLinks" :ext_links="getExternalLinks"/>                            
                   
                </p>
            </div>

            </div>


            <div class="row more">

            <div v-if="collection_name=='pmc' || collection_name=='medline'">
                <button class="link-button" @click="displayViewerFullPage">One Tab View</button>
                <FulltextViewer :id="document._id" :collection="collection_name" :terms_to_highlight="highlighted_terms"/>
            </div>

            <div v-if="collection_name=='plazi'">
            <div v-if="getText.length > 0">
                <label>Text</label>
                <p v-html="split(highlight(getText))"></p>
            </div>                
            </div>

            <div v-if="collection_name=='suppdata'">

            <div v-if="isImage">
                <img :src="getLinkSupp"/>
                <br/>
            </div>

            <a :href="getLinkSupp" target="_blank">Download file {{ getFileName }}</a>
            <br/><br/>

            <div v-if="getText.length > 0">
                <label>Text</label>
                <p v-html="split(highlight(getText))"></p>
            </div>  

            </div>

        </div>

   
    </div>

    <div v-if="warning" class="alert alert-warning">
            Document {{ document_id }} not found
        </div>

</div>


</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import Bookmark from '@/components/Bookmark.vue'
    import FulltextViewer from '@/components/FulltextViewer.vue'
    import shared from '@/components/shared.js'
    import axios from 'axios';
    var PulseLoader = require('vue-spinner/src/PulseLoader.vue').default;
    import ExternalLinks from '@/components/ExternalLinks.vue'
    let exclusion = require('../assets/js/exclusion.js');


    export default {
      name: 'ResultSearchDocument',
      components: {
        Bookmark,
        FulltextViewer,
        PulseLoader,
        ExternalLinks
      },
      props: {
        document_init: {
            type: Object,
            default: null
        },
        document_id: {
            type: String,
            default: null
        },
        collection_name: {
            type: String,
            required: true
        },
        collection_title: {
            type: String,
            required: true
        },
        rank: {
            type: Number,
            default: 0,
        },
        page: {
            type: Number,
            default: 0,
        },
        per_page: {
            type: Number,
            default: 10,
        },
      },
      data (){
        return {
            bookmark_status: false,
            expanded_status: false,
            document: {},
            in_progress: false,
            warning: null,
            biotic_passage: '',
            highlighted_terms: [],
            highlighted_terms_detailed: [],
            ebiodiv_params: null
        };
      },
      computed: {
        ...mapState(['user_query', 'user_selection', 'urls', 'pmca_parameters', 'theme_color', 'fields', 'doc_source']),
        getRank(){
           return ((this.page-1)*this.per_page)+(this.rank+1)
        },
        get_report_link(){
          var url = window.location.href
          var params = (new URL(document.location)).searchParams;
          var label = "biodiversityPMC"
          var service = "Biodiversity PMC"
          if(this.$route.name === "CellTriagePage"){
                label = "celltriage"
                service = "celltriage"
            }
          return "https://github.com/sibils/community/issues/new?template=bugreport.yaml&labels=bug,"+label+"&service="+service+"&problem="+encodeURI("issue with document "+this.getId+" from collection "+this.collection_name)+"&url="+encodeURI(url+params)
        },
        getId(){
            if ("_id" in this.document){
                return this.document._id
            }
            else{
                return ""
            }
        },
        getTitle(){
            if (this.doc_source in this.document){
                return this.document[this.doc_source].title
            }
            else{
                return ""
            }
        },
        getTreatmentTitle(){
            if ([this.doc_source] in this.document){
                return this.document[this.doc_source].treatment_title+". "+this.document[this.doc_source]['article-title']
            }
            else{
                return ""
            }
        },
        getAbstract(){
            if (this.doc_source in this.document){
                return this.document[this.doc_source].abstract
            }
            else{
                return ""
            }
        },
        getMeshs(){
            if (this.doc_source in this.document){
                if (Array.isArray(this.document[this.doc_source].mesh_terms)){
                    return this.document[this.doc_source].mesh_terms.join(",")
                }
                else {
                    return this.document[this.doc_source].mesh_terms.replaceAll('|', ', ')
                }
            }
            else{
                return ""
            }
        },
        getKeywords(){
            if (this.doc_source in this.document){
                if (Array.isArray(this.document[this.doc_source].keywords)){
                    return this.document[this.doc_source].keywords.join(",")
                }
                else {
                    return this.document[this.doc_source].keywords.replaceAll('|', ', ')
                }
            }
            else{
                return ""
            }
        },
        getChemicals(){
            if (this.doc_source in this.document){
                if (Array.isArray(this.document[this.doc_source].chemicals)){
                    return this.document[this.doc_source].chemicals.join(",")
                }
                else {
                    return this.document[this.doc_source].chemicals.replaceAll('|', ', ')
                }
            }
            else{
                return ""
            }
        },
        getText(){
            if (this.doc_source in this.document){
                return this.document[this.doc_source].text
            }
            else{
                return ""
            }
        },
        getFileName(){
            if (this.doc_source in this.document){
                return this.document[this.doc_source].filename
            }
            else{
                return ""
            }
        },
        getAuthors() {
            if (this.doc_source in this.document && 'authors' in this.document[this.doc_source]) {
                var authors = []
                if (Array.isArray(this.document[this.doc_source].authors)){
                    authors = this.document[this.doc_source].authors
                }
                else {
                    authors = this.document[this.doc_source].authors.split("|")
                }
                if (authors.length <= 7){
                    return authors.join(', ')+". "
                }
                else {
                    return authors.slice(0, 5).join(', ')+ ", et al. "
                }
            }
            else{
                return ""
            }
        },
        getDate() {
            if (this.doc_source in this.document){
                if ('entrez_date' in this.document[this.doc_source] && this.document[this.doc_source].entrez_date != null) {
                    return this.document[this.doc_source].entrez_date+". "
                }
                else if ('pmc_release_date' in this.document[this.doc_source] && this.document[this.doc_source].pmc_release_date != null)  {
                    if ('publication_date' in this.document[this.doc_source] && this.document[this.doc_source].publication_date != this.document[this.doc_source].pmc_release_date){
                        return this.document[this.doc_source].publication_date +" (released: "+this.document[this.doc_source].pmc_release_date+"). "
                    }
                    else {
                        return this.document[this.doc_source].pmc_release_date+". "
                    }
                }
                else if ('publication_date' in this.document[this.doc_source] && this.document[this.doc_source].publication_date != null) {
                    return this.document[this.doc_source].publication_date+". "
                }
                else if ('pubyear' in this.document[this.doc_source] && this.document[this.doc_source].pubyear != null) {
                    return this.document[this.doc_source].pubyear+". "
                }
            }
            return ""
        },
        getJournal() {
            if (this.doc_source in this.document && 'journal' in this.document[this.doc_source]) {
                return this.document[this.doc_source].journal+". "
            }
            else{
                return ""
            }
        },
        getLicence(){
            if (this.doc_source in this.document && 'licence' in this.document[this.doc_source] && this.document[this.doc_source].licence != null) {
                return "Licence "+this.document[this.doc_source].licence+". "
            }
            else{
                return ""
            }
        },
        getPublicationTypes() {
            if (this.doc_source in this.document && 'publication_types' in this.document[this.doc_source]) {
                if (Array.isArray(this.document[this.doc_source].publication_types)){
                    return this.document[this.doc_source].publication_types.join(",")+". "
                }
                else {
                    return this.document[this.doc_source].publication_types.replaceAll('|', ', ')+". "
                }
            }
            else{
                return ""
            }
        },
        getInternalLinks(){
            var links = []
            if (this.doc_source in this.document){ 
                
                if ('pmcid' in this.document[this.doc_source] && this.document[this.doc_source].pmcid != ""){
                    if (this.collection_name == "pmc" || ('fulltext' in this.document && this.document['fulltext'] == true)){
                        var linkpmca = {}
                        linkpmca.name = "PMCViewer"
                        linkpmca.url = this.getPMCALink('pmc', this.document[this.doc_source].pmcid);
                        links.push(linkpmca)
                    }
                }
                var linksibils = {}
                linksibils.name = "BiodiversityPMC"
                var docid = this.document._id
                if (this.collection_name == "suppdata"){
                    docid = this.document[this.doc_source].pmcid
                }
                if (this.collection_name == "pmc" && this.document[this.doc_source].pmcid == ""){
                    docid = encodeURI(this.document[this.doc_source].doi)
                }
                linksibils.url = process.env.VUE_APP_URL_PUBLIC_PATH + "?id="+docid
                links.push(linksibils)
                //if (!(this.collection_name == "pmc" && this.document[this.doc_source].pmcid == "")){
                    var linkbiotic = {}
                    var encoded_id = encodeURIComponent(this.document._id)
                    linkbiotic.name = "BiotXplorer"
                    linkbiotic.url = this.urls["biotxplorer"] + "collections/"+this.collection_name+"/"+encoded_id
                    links.push(linkbiotic)
                //}
                if (this.collection_name == "pmc" && 'pmcid' in this.document[this.doc_source] && this.document[this.doc_source]["pmcid"] != "") {
                    var linksparql = {}
                    linksparql.name = "SPARQL Endpoint"
                    linksparql.url = this.urls["sparql_endpoint"] + "?default-graph-uri=&qtxt=PREFIX%20%3A%20%3Chttp%3A%2F%2Fsibils.org%2Frdf%23%3E%0APREFIX%20sibils%3A%20%3Chttp%3A%2F%2Fsibils.org%2Frdf%2Fdata%2F%3E%0APREFIX%20rdf%3A%20%3Chttp%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%3E%0APREFIX%20rdfs%3A%20%3Chttp%3A%2F%2Fwww.w3.org%2F2000%2F01%2Frdf-schema%23%3E%0APREFIX%20skos%3A%20%3Chttp%3A%2F%2Fwww.w3.org%2F2004%2F02%2Fskos%2Fcore%23%3E%0A%0ASELECT%0A%20%20(str(%3Fdoi)%20as%20%3Fdoi)%0A%20%20(str(%3Fcpt_id)%20as%20%3Fcpt_id)%0A%20%20(str(%3Fscheme)%20as%20%3Fscheme)%0A%20%20(str(%3Fstart)%20as%20%3Fstart)%0A%20%20(str(%3Ftoken)%20as%20%3Ftoken)%0A%20%20(str(%3Fpart)%20as%20%3Fsection)%0A%20%20(str(%3Fpart_type)%20as%20%3Fpart_type)%0AWHERE%20%7B%0A%20%20VALUES%20%3Fpubli%20%7B%20sibils%3A"+docid+"%20%7D%20.%0A%20%20%3Fpubli%20%3Adoi%20%3Fdoi.%0A%20%20%3Fpubli%20%3AhasAnnotation%20%3Fa%20.%0A%20%20%3Fa%20%3AhasBody%20%3Fcpt%20.%0A%20%20%3Fcpt%20skos%3Anotation%20%3Fcpt_id%20.%0A%20%20%3Fcpt%20skos%3AinScheme%20%3Fscheme_IRI%20.%0A%20%20%3Fscheme_IRI%20%20rdfs%3Alabel%20%3Fscheme%20.%0A%0A%20%20%3Fa%20%3AhasTarget%20%3Ftrg.%0A%20%20%3Ftrg%20%3AhasSource%20%3Fpart.%0A%20%20%3Fpart%20a%20%3Fpart_type%20.%0A%0A%20%20%3Ftrg%20%3AhasSelector%20%3Fsel%20.%0A%20%20%3Fsel%20%3Astart%20%3Fstart.%0A%20%20%3Fsel%20%3Aexact%20%3Ftoken.%0A%20%20%23%20FILTER%20(%3Fpart_type%20!%3D%20%3AParagraph%20)%0A%7D%0AORDER%20BY%20%3Fpart%20%3Fstart%20%3Ftoken&format=text%2Fhtml&should-sponge=&timeout=0&signal_void=on"
                    links.push(linksparql)
                }
                if ('treatment-bank-uri' in this.document[this.doc_source] && this.document[this.doc_source]['treatment-bank-uri'] != ""){
                    var linktb = {}
                    linktb.name = "TreatmentBank"
                    linktb.url = this.document[this.doc_source]['treatment-bank-uri']
                    links.push(linktb)
                }
                if (this.collection_name == "plazi"){
                    if (this.ebiodiv_params != null){
                        var linkmatching = {}
                        linkmatching.name ="eBioDiv matching service"
                        //linkmatching.url = "https://prod.ebiodiv.org?occurrencesKeys="+this.gbif_keys.join(",");
                        linkmatching.url = "https://prod.ebiodiv.org?"+this.ebiodiv_params
                        links.push(linkmatching)
                    }
                }
                if (this.collection_name != "pmc" || ('subset' in this.document[this.doc_source] && this.document[this.doc_source].subset != "PMC Author Manuscripts")){
                    var linkjson = {}
                    linkjson.name = "BioC"
                    linkjson.url = this.urls["fetch"] + "?ids="+encodeURIComponent(this.document._id)+"&col="+this.collection_name+"&format=bioc"
                    links.push(linkjson)
                }
                if (this.collection_name == "suppdata"){
                    var linksupp = {}
                    linksupp.name = "Supp. data text file"
                    linksupp.url = process.env.VUE_APP_URL_PUBLIC_PATH + "collections/suppdata/"+encodeURIComponent(this.document._id)
                    links.push(linksupp)
                }
            }      
            return links
        },
        getExternalLinks(){
            var links = []
            if (this.doc_source in this.document){
                if ('pmid' in this.document[this.doc_source] && this.document[this.doc_source].pmid != ""){
                    var linkmed = {}
                    linkmed.name = "MEDLINE"
                    linkmed.url = "https://pubmed.ncbi.nlm.nih.gov/"+this.document[this.doc_source].pmid
                    links.push(linkmed)
                }
                if ('pmcid' in this.document[this.doc_source] && this.document[this.doc_source].pmcid != ""){
                    var linkpmc = {}
                    linkpmc.name = "PubMedCentral"
                    linkpmc.url = "https://www.ncbi.nlm.nih.gov/pmc/articles/"+this.document[this.doc_source].pmcid
                    links.push(linkpmc)
                    var linkeurope = {}
                    linkeurope.name = "EuropePMC"
                    linkeurope.url = "https://europepmc.org/article/PMC/"+this.document[this.doc_source].pmcid
                    links.push(linkeurope)
                }
                if ('doi' in this.document[this.doc_source] && this.document[this.doc_source]['doi'] != ""){
                    var linkdoi = {}
                    linkdoi.name = "Publisher"
                    var prefix_doi_url = ""
                    if (!this.document[this.doc_source]['doi'].startsWith("http")){
                        prefix_doi_url = "http://dx.doi.org/"
                    }
                    linkdoi.url = prefix_doi_url+this.document[this.doc_source]['doi']
                    links.push(linkdoi)
                }
                if ('zenodo-doi' in this.document[this.doc_source] && this.document[this.doc_source]['zenodo-doi'] != "" && this.document[this.doc_source]['zenodo-doi'] != null){
                    var linkzen = {}
                    linkzen.name = "Publisher/Zenodo"
                    var prefix_url = ""
                    if (!this.document[this.doc_source]['zenodo-doi'].startsWith("http")){
                        prefix_url = "http://dx.doi.org/"
                    }
                    linkzen.url = prefix_url+this.document[this.doc_source]['zenodo-doi']
                    links.push(linkzen)
                }
                
                if ('filename' in this.document[this.doc_source] && this.document[this.doc_source]['filename'] != ""){
                    var linksd = {}
                    linksd.name = "Supp. data file"
                    linksd.url = this.getLinkSupp
                    links.push(linksd)
                }
            }      
            return links
        },
        isImage(){
            if (this.document[this.doc_source].filename.toLowerCase().endsWith('.jpg')){
                return true
            }
            else if (this.document[this.doc_source].filename.toLowerCase().endsWith('.png')){
                return true
            }
            if (this.document[this.doc_source].filename.toLowerCase().endsWith('.tiff')){
                return true
            }
            return false
        },
        getLinkSupp(){
            if ('_id' in this.document) {
                return "https://www.ncbi.nlm.nih.gov/pmc/articles/"+this.document[this.doc_source].pmcid+"/bin/"+this.document[this.doc_source].filename
            }
            else{
                return ""
            }
        },
        getSource(){
            if ('_id' in this.document) {
                var urlpmcviewer = this.getPMCALink(this.collection_name, this.document._id);
                if (this.$route.name === "CellTriagePage"){
                    urlpmcviewer += "&cello"
                }
                return urlpmcviewer
            }
            else{
                return ""
            }
        },
        getScore() {
            if ("_score" in this.document) {
                return this.document._score.toFixed(2)
            }
            else if ("score" in this.document) {
                return this.document.score.toFixed(2)
            }
            else{
                return ""
            }
        },
        isInList(){
            if (this.collection_title in this.user_selection.selected_documents){
                if (this.document._id in this.user_selection.selected_documents[this.collection_title]){
                    return true;
                }
            }
            return false;
        },
      },
      methods: {
        ...mapActions(['updateHighlightTerms', 'updateQueryPmcviewer']),
        cssVars () {
            return{
            '--color-main': this.theme_color.main,
            }
        },
        isNotDOI(text) {
            const doiPattern = /^10\.\d{4,9}\/[-._;()/:A-Z0-9]+$/i;
            return !doiPattern.test(text);
        },
        getPMCALink(collection, doc_id) {
            // for all parameters, see https://github.com/sibils/pmca-tool/blob/465a0036e60e29b944bd2e5d856e631eacf412fd/src/pmca-element.js#L219-L238
            const pmca_doc_params = {
                id: doc_id,
                col: collection,
                showbacksections: '',
            }
            const mergedParams = { ...this.pmca_parameters, ...pmca_doc_params };
            const queryParams = new URLSearchParams(mergedParams);
            const queryString = queryParams.toString();
            return this.urls["pmca"] + "/index.html?" + queryString
        },
        getAnnotations(){
            var valid_codes = []
            var highlighted_terms = []
            for (let n=0; n<this.user_query.normalized_concepts.length; n++){
            let annotations = this.user_query.normalized_concepts[n]
                for (let a=0; a<annotations.length; a++){
                    if (annotations[a].checked == true){
                    valid_codes.push((annotations[a].concept_source+":"+annotations[a].concept_id).toLowerCase())
                    }
                }
            }
            if (valid_codes.length > 0){
                if ('annotations_material' in this.document._source && this.document._source['annotations_material'] != ""){
                    let annotations = this.document._source['annotations_material'].split("||")
                    for (let a=0; a<annotations.length; a++){
                    let annotation = annotations[a].split("|")
                    if (annotation.length >= 3){
                        if (valid_codes.includes((annotation[0]+":"+annotation[1]).toLowerCase())){
                            for (let e=2; e<annotation.length; e++){
                                highlighted_terms.push(annotation[e])
                            }
                        }
                    } 
                    }
                }     
            }
            highlighted_terms = [...new Set(highlighted_terms)]

            if (this.user_query.mode_exec != "id" && this.isNotDOI(this.user_query.search)){
                var query_terms = this.user_query.search.split(/\W+/)
                for (var q=0; q<query_terms.length; q++){
                    var q_term = query_terms[q]
                    highlighted_terms.push(q_term)
                }
            }
            else{
                highlighted_terms.push(this.user_query.search)
            }

            for (var i=0; i<highlighted_terms.length; i++){
                var word = highlighted_terms[i]
                //word = word.toLowerCase().replace(/[.,\\/#!$%\\^&\\*;:{}=\-_`~()]/g, "").replace(/\s{2,}/g, " ")
                //word = word.toLowerCase()
                word = word.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
                word = word.replace(/££££££/g, '\\')
                highlighted_terms[i] = word
            }

            exclusion.push("or")
            exclusion.push("and")

            highlighted_terms = highlighted_terms.filter(function(val) {
                return exclusion.indexOf(val) == -1;
            });


            highlighted_terms = highlighted_terms.filter(element => {
                return element !== '';
            });

            highlighted_terms = highlighted_terms.filter(element => {
                return element.length != 1;
            });

            highlighted_terms = [...new Set(highlighted_terms)];

            this.highlighted_terms = highlighted_terms

        },
        highlight(text){
            if(this.$route.name === "CellTriagePage"){
                return(shared.highlight(text, this.highlighted_terms))
            }
            else {
                return(shared.highlight(text, this.highlighted_terms))
            }
        },
        split(text){
            text = text.replaceAll("\n", "<br/>")
            return text
        },
        fetch(){
            this.warning = null
            var url = this.urls['search']
            var final_query = {}
            final_query['query'] = {}
            final_query['query']['ids'] = {}
            final_query['query']['ids']['values'] = [this.document_id]
            // TODO fix here
            final_query["_source"] = this.fields[this.collection_name].split(",")
            this.cancel();
            let axiosSource = axios.CancelToken.source();
            this.request = { cancel: axiosSource.cancel };
            let body = {'jq': JSON.stringify(final_query), 'col': this.collection_name, 'n': 1}
            axios
            .post(url, body, {
            cancelToken: axiosSource.token,
            })
            .then(response => {
              if ('success' in response.data && response.data.success == false){
                this.error = response.data.error
                this.total = 0
              }
              else {
                this.error = null
                console.log(response.data)
                var documents = response.data.elastic_output.hits.hits
                if (documents.length > 0){
                     this.document = documents[0]
                     this.getAnnotations()
                     if (this.user_query.ids != ""){
                          if("title" in this.document._source){
                             window.document.title = this.document._source.title
                          }
                          else if("treatment_title" in this.document._source){
                             window.document.title = this.document._source.treatment_title
                          }
                          else {
                              window.document.title = this.document_id
                          }
                     }
                }
                else {
                    this.warning = true
                }
                this.in_progress = false
              }
              this.in_progress = false
            })
            .catch(error => {
                alert(error)
              if(error != "Cancel"){
                alert ("Failed to fetch document: "+error)
              }
            })
      },
      cancel() {
        if (this.request) this.request.cancel();
      },
      getEbiodivParams(){
        var url2 = "https://tb.plazi.org/GgServer/srsStats/stats?outputFields=matCit.gbifOccurrenceId&groupingFields=matCit.gbifOccurrenceId&limit=100&FP-doc.uuid="+this.getId+"&format=JSON"
        axios
        .get(url2)
        .then(response => {
            var results = response.data.data
            var keys = []
            for (var k=0; k<results.length; k++){  
                if (results[k].MatCitGbifOccurrenceId != 0){
                    keys.push(results[k].MatCitGbifOccurrenceId)
                }
            }
            if (keys.length > 0){
                var url = "https://tb.plazi.org/GgServer/srsStats/stats?outputFields=doc.articleUuid&groupingFields=doc.articleUuid&orderingFields=doc.name&limit=100&FP-doc.uuid="+this.getId+"&format=JSON"
                axios
                .get(url)
                .then(response => {
                    if (response.data.data.length>0){
                        var article_id = response.data.data[0].DocArticleUuid
                        if (article_id != 0){
                            var params = "basisOfRecord=MATERIAL_CITATION&plaziUuid="+article_id+"&scientificName="+encodeURIComponent(this.document[this.doc_source]["treatment_title"].split(" ").slice(0, 2).join(" ")+"*")
                            // Check if there are occurrences for the doc id
                            this.ebiodiv_params = params
                        }
                    }
                })
                .catch(error => {
                    console.log("Failed to search gbig: "+error)
                })
            }
        })   
      },
      expandDoc(){
        if (this.expanded_status == false){
            this.$matomo?.trackEvent("Expand document positions for "+this.collection_name, this.getRank);
            this.$matomo?.trackEvent("Expand document", this.collection_name+":"+this.getRank+";"+this.getId);
        }
        this.expanded_status = !this.expanded_status
      },
      displayViewerFullPage(){
            this.updateQueryPmcviewer({"id": this.document._id, "collection": this.collection_name, "highlighted_terms": this.highlighted_terms})
      },


    },
      beforeMount() {
        if (this.document_init == null){
            this.in_progress = true
            this.fetch()
        }
        else{
            this.document = this.document_init
            if (this.$route.name === 'CellTriagePage'){
                this.highlighted_terms = []
                var annotations = this.document.annotations
                for (let a=0; a<annotations.length; a++){
                    var elements = annotations[a]
                    if (elements.concept_source == 'cellosaurus'){
                        if (/[a-zA-Z]/.test(elements.concept_form)){
                            this.highlighted_terms.push(elements.concept_form)
                        }
                    }
                }
            }
            else {
                this.getAnnotations()
            }
        }
      },
      mounted() {
        if (this.collection_name == "plazi"){
            this.getEbiodivParams()
        }
      }
    }

</script>

<style scoped lang="scss">

    .article-container {
        border: 5px #F2F2F2 solid;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) unset;
        border-collapse: separate;
        margin: 10px 0px;
        padding: 0px 0px;
    }

    .left-column {
      padding: 10px;
      background-color: #fff;
    }

    .middle-column {
      text-align: justify;
      padding: 0px 20px;
      background-color: #fff;
    }

    .right-column {
        background-color: #F2F2F2;
        text-align: center;
        padding: 0 5px 5px 5px;
    }

    .rank {
        background-color: #A2A2A2;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        min-width: 30px;
        padding: 0;
        border-radius: 20px;
    }

    .biblio, .resume, .more {
        font-size: 0.9em;
        text-align: justify
    }

    .more label {
        font-weight: bold;
    }

    .article-menu {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .score {
        font-weight: bold;
    }

    .expand {
        width: 100%;
    }

    .expand img {
        width: 20px;
    }

    .expand img:hover {
        cursor: pointer;
    }

    .link {
        color: #2c3e50;
    }

    .link:hover {
        color: #2c3e50;
    }

    .bug-link {
        color: #bbb;
        font-style: italic
    }
    .bug-link:hover {
        color: #bbb
    }

    .link-button {
        background: none;
        border: none;
        color: red;
        text-decoration: underline;
        cursor: pointer;
        padding: 0;
        font-size: 1.2em;
        }

        .link-button:hover {
        text-decoration: none; /* Optionnel : enlève le soulignement au survol */
        }
</style>
