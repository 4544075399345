<template>

    <div class="component-container" v-show="visible">

      <div v-show="user_query.pmcviewer == null">

        <h1 id="results-section">Results <span v-if="user_query.search.length > 0">for </span> {{ user_query.search }} </h1>
  
          <b-tabs content-class="mt-3" justified pills v-show="user_query.mode_exec=='search' || user_query.mode_exec=='id'">
              <ResultsSearchCollection ref="resultsSearchMedline" :collection_title="collections['medline']" collection_name="medline" document_name="abstract" :tab="1"/>
              <ResultsSearchCollection ref="resultsSearchPmc" :collection_title="collections['pmc']" collection_name="pmc" document_name="full-text article" :tab="2"/>
              <ResultsSearchCollection ref="resultsSearchSuppData" :collection_title="collections['suppdata']" collection_name="suppdata" document_name="file" :tab="3"/>
              <ResultsSearchCollection ref="resultsSearchPlazi" :collection_title="collections['plazi']" collection_name="plazi" document_name="taxonomic treatment" :tab="4"/>
          </b-tabs>
          <b-tabs content-class="mt-3" justified pills v-show="user_query.mode_exec=='qa'">
              <ResultsQaCollection ref="resultsQaMedline" collection_title="MEDLINE" collection_name="medline" :tab="1"/>
              <ResultsQaCollection ref="resultsQaPlazi" collection_title="PLAZI" collection_name="plazi" :tab="3"/>
          </b-tabs>

      </div>

      <div v-show="user_query.pmcviewer != null">

        <button class="link-button" @click="hideViewerFullPage">Back to results</button>
        <FulltextViewer v-if="user_query.pmcviewer != null" :id="user_query.pmcviewer.id" :collection="user_query.pmcviewer.collection" :terms_to_highlight="user_query.pmcviewer.highlighted_terms"/>
        <br/><button class="link-button" @click="hideViewerFullPage">Back to results</button>

      </div>


    </div>

</template>


<script>
import ResultsSearchCollection from '@/components/ResultsSearchCollection.vue'
import ResultsQaCollection from '@/components/ResultsQaCollection.vue'
import FulltextViewer from '@/components/FulltextViewer.vue'
import { mapState, mapActions } from 'vuex'

    export default {
      name: 'ResultsBox',
      components: {
        ResultsSearchCollection,
        ResultsQaCollection,
        FulltextViewer
      },
      data() {
        return {
          visible: false,
        };
      },
      computed: {
        ...mapState(['user_query', 'collections']),
    },
      methods:{
       ...mapActions(['updateHighlightTerms', 'clearHighlightTerms', 'updateTab', 'updateQueryPmcviewer']),
       search(){
            this.updateTab(0)
            this.clearHighlightTerms()
            this.updateHighlightTerms(this.user_query.original.toLowerCase().replaceAll("?", "").split(" "))
            this.visible = true
            if(this.user_query.mode_exec == 'qa'){
              this.$refs.resultsQaMedline.search()
              this.$refs.resultsQaPlazi.search()
            }
            else if(this.user_query.mode_exec == 'search' || this.user_query.mode_exec == "id") {
              this.$refs.resultsSearchMedline.search()
              this.$refs.resultsSearchPmc.search()
              this.$refs.resultsSearchSuppData.search()
              this.$refs.resultsSearchPlazi.search()
            }
            window.document.title = this.user_query.original
            this.$matomo?.trackEvent("Query", this.user_query.original);
            this.$matomo?.trackEvent("Query type", this.user_query.mode_exec);
            this.$matomo?.trackEvent("Query detailled", JSON.stringify(this.user_query));
            this.$router.push({ name: 'HomePage', hash: '#results-section', query: this.$route.query}).catch(()=>{});
       },
       hideViewerFullPage(){
            this.updateQueryPmcviewer(null)
        }
      },
    }

</script>


<style scoped lang="scss">

  .export-container {
    margin: 40px 0px;
  }
  .link-button {
  background: none;
  border: none;
  color: red;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-size: 1.2em;
}

.link-button:hover {
  text-decoration: none; /* Optionnel : enlève le soulignement au survol */
}


</style>
