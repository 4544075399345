<template>

    <div class="example-container">

      <h3>Search examples</h3>
      <a v-for="(example, index) in examples.search_mode" :key="index+'example_search'" @click="updateQueryAndLog(example.translated)">{{ example.query }}<br/></a>
      <br/>
      <h3>Question-answering examples</h3>
      <p class="info">The question-answering mode is limited to MEDLINE and PLAZI collections.</p>
      <a v-for="(example, index) in examples.qa_mode" :key="index+'example_qa'" @click="updateQueryAndLog(example.translated)">{{ example.query }}<br/></a>
      <br/>
      <h3>Examples for other services</h3>
      <a v-for="(example, index) in examples.other_mode" :key="index+'example_other'" target="_blank" :href="example.url">{{ example.query }}<br/></a>

    </div>

</template>


<script>
import { mapState, mapActions } from 'vuex'

    export default {
      name: 'QueryExample',
      components: {
      },
      data() {
        return {

        };
      },
      computed: {
        ...mapState(['examples']),
      },
      methods:{
        ...mapActions(['updateQueryOriginal']),
        updateQueryAndLog(example){
          this.$matomo?.trackEvent("Select example", example);
          this.updateQueryOriginal(example)
        }
      },
    }

</script>


<style scoped lang="scss">

    .example-container {
        margin: 20px 0;
        text-align: left;
    }

</style>
